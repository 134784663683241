.main{
  padding: 10.25em 0 10em;
  background: radial-gradient(45.02% 45.02% at -14.74% 56.75%, #FFB422 4.07%, rgba(255, 180, 34, 0) 100%), radial-gradient(67.58% 67.58% at -13.86% 53.23%, #FFB422 1.49%, rgba(255, 180, 34, 0) 51.61%) , radial-gradient(42.19% 42.19% at -3.33% 41.61%, #FFB422 0%, rgba(255, 180, 34, 0) 100%) , radial-gradient(62.28% 49.4% at 28.14% 110.65%, #F457EC 9.89%, rgba(244, 87, 236, 0) 100%) , radial-gradient(38.93% 30.88% at 0.94% 24.13%, #FFB422 0%, rgba(255, 180, 34, 0) 100%) , radial-gradient(58.88% 46.71% at -6.51% 86.63%, #FF3838 36.46%, rgba(255, 56, 56, 0) 100%) , radial-gradient(50.1% 50.1% at 11.69% 14.46%, #F457EC 0%, rgba(244, 87, 236, 0) 100%), #1A9EFE;
  border-radius: 0 0 2.5em 2.5em;
  color: #fff;
  &__inner{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  &__logo{
    margin-bottom: 1.25em;
    img{
      width: 100%;
      display: block;
    }
  }
  &__btn{
    margin-top: 2.5em;
    width: 15.875em;
    max-width: 100%;
  }
}

.z-3{
  position: relative;
  z-index: 3;
}

.who,
.team,
.cool{
  .display1{
    margin-top: .142em;
    margin-left: -.06em;
  }
  &__text{
    max-width: 846px;
    margin: .625em 0 0 auto;
    position: relative;
    z-index: 2;
  }
}

.who{
  background-color: $black;
  padding: 12.5em 0 10em;
  margin-top: -2.5em;
  color: #FFFFFF;
  overflow: hidden;
  &__inner{
    position: relative;
    padding-bottom: 19em;
  }
  &__lips{
    position: absolute;
    top: 41.25em;
    width: 13.9375em;
    left: 29.25em;
    transform: rotate(-14deg);
  }
}

.round{
  width: 11em;
  height: 11em;
  border-radius: 50%;
  position: absolute;
  filter: blur(9.75em);
  background: #1A9EFE;
  pointer-events: none;
}

.round1{
  top: -1.375em;
  right: 5em;
}

.round2{
  top: 18.125em;
  left: .875em;
  background: $primary;
}

.round3{
  top: 30.25em;
  left: 9.25em;
}

.round4{
  right: 4.25em;
  top: 29.625em;
  opacity: 0.67;
  width: 18.8125em;
  height: 18.8125em;
}

.who-img{
  position: absolute;
  display: block;
}

.who-img1{
  width: 7.75em;
  top: 19em;
  left: 1.625em;
  transform: rotate(13deg);
  border-radius: 1.875em;
}

.who-img2{
  width: 15.125em;
  top: 33.25em;
  left: 1.6875em;
  transform: rotate(-9deg);
  border-radius: 3.125em;
}

.who-img3{
  top: 10.8125em;
  right: 4.5em;
  width: 9.9375em;
  border-radius: 2.5em;
  transform: rotate(10deg);
}

.who-img4{
  width: 23.25em;
  border-radius: 3.125em;
  top: 31.625em;
  right: -1.125em;
  transform: rotate(8deg);
}

.nums{
  margin-top: 10.625em;
  display: flex;
}

.num{
  flex-shrink: 0;
  position: relative;
  &:not(:last-child){
    margin-right: 15em;
  }
  &__img{
    height: 22.5em;
    width: auto;
    margin-bottom: 2.1875em;
    display: block;
  }
  &__ear{
    position: absolute;
    left: 19.25em;
    top: -6.25em;
    width: 12.25em;
    transform: rotate(-190deg);
  }
  &__girl{
    position: absolute;
    top: 7em;
    left: 100%;
    margin-left: -5.625em;
    width: 21.4375em;
  }
}

.team{
  background-color: $primary;
  border-radius: 2.5em;
  margin-top: -2.5em;
  padding: 10em 0;
  position: relative;
  z-index: 2;
  &__inner{
    position: relative;
  }
  .display1{
    position: relative;
    z-index: 2;
  }
  &__text2{
    margin-top: 18.5em;
  }
  &__ear{
    position: absolute;
    left: -.1875em;
    top: 44.75em;
    width: 10.75em;
    display: block;
    transform: rotate(-190deg);
  }
  &__mask{
    position: absolute;
    width: 7.5em;
    right: 1.875em;
    top: 26.5em;
  }
  @media screen and (min-width: 993px){
    .swiper{
      &-wrapper{
        position: static;
        transform: unset !important;
      }
      &-slide{
        width: 12.5em !important;
        position: absolute;
        height: auto;
        &:nth-child(1){
          top: 0;
          right: 2.125em;
          transform: rotate(5.97deg);
        }
        &:nth-child(2){
          top: 18.625em;
          left: -2.8125em;
          z-index: 3;
          transform: rotate(-5deg);
        }
        &:nth-child(3){
          top: 31.25em;
          left: 8.5625em;
          transform: rotate(2.8deg);
        }
        &:nth-child(4){
          top: 33.25em;
          left: 23.375em;
          transform: rotate(-4deg);
        }
        &:nth-child(5){
          top: 30em;
          left: 37.8125em;
          z-index: 3;
          transform: rotate(-4deg);
        }
        &:nth-child(6){
          top: 36em;
          left: 50.25em;
          transform: rotate(4deg);
        }
      }
    }
  }
}

.team-card{
  padding: .5625em;
  background: #FFFFFF;
  border-radius: 1.25em;
  font-family: $font2;
  border: 1px solid $black;
  &__img{
    @include imgCover(89%);
    img{
      border-radius: .625em;
      display: block;
    }
  }
  &__content{
    padding: .75em 0;
    min-height: 6.25em;
    text-align: center;
  }
  &__title{
    font-weight: 600;
    font-size: 1.125em;
    line-height: 138.8%;
    letter-spacing: -0.02em;
  }
  &__prof{
    font-size: .875em;
    line-height: 114%;
    margin-top: .35em;
  }
}

.make{
  margin-top: -2.5em;
  background-color: $black;
  padding: 12.5em 0;
  color: #FFFFFF;
  &__inner{
    position: relative;
  }
  &__title{
    margin-top: .142em;
    margin-left: -.06em;
  }
  &__items{
    margin-top: -1.1875em;
    position: relative;
    z-index: 2;
  }
  &__title2{
    margin: -.42em 0 0 -.05em;
    width: calc(100% + .1em);
  }
  &__lips{
    top: 4.625em;
    right: -1.5625em;
    width: 14em;
    display: block;
    position: absolute;
  }
}

.make-card{
  padding: 1.25em;
  border-radius: 1.25em;
  background-color: $primary;
  display: grid;
  grid-template-columns: 20.1875em calc(100% - 20.1875em);
  min-height: 12em;
  color: $black;
  transform: rotate(2deg);
  &:nth-child(even){
    background-color: #fff;
    transform: rotate(-1deg);
  }
  &:nth-child(3n){
    transform: rotate(1deg);
  }
}

.round5{
  width: 28.5625em;
  height: 28.5625em;
  background-color: $primary;
  opacity: .67;
  left: -4em;
  bottom: 14.125em;
}

.round6{
  width: 18.8125em;
  height: 18.8125em;
  background-color: $primary;
  opacity: .67;
  right: -3.25em;
  top: 27.3125em;
}

.cool{
  padding: 10em 0;
  background-image: url(../img/cool.jpg);
  background-size: cover;
  background-position: center;
  border-radius: 2.5em;
  margin-top: -2.5em;
  overflow: hidden;
  &__top{
    color: #fff;
    position: relative;
  }
  &__butterfly{
    position: absolute;
    right: 20.9375em;
    top: 2.8125em;
    width: 7.0625em;
    transform: rotate(190deg);
  }
  &__tags{
    margin-top: 4.25em;
    display: flex;
    flex-wrap: wrap;
  }
  &__tag{
    font-family: $font2;
    padding: .5em 1.875em;
    min-height: 6.75em;
    border-radius: 1.25em;
    display: flex;
    align-items: center;
    background-color: #fff;
    margin: 0 .75em;
    //opacity: 0;
    &:nth-child(odd){
      background-color: $primary;
    }
    span{
      letter-spacing: -0.02em;
      font-size: 2.125em;
      line-height: 141%;
    }
    &:nth-child(1){
      transform: rotate(-1deg);
    }
    &:nth-child(2){
      margin-top: .3125em;
      transform: rotate(1deg);
    }
    &:nth-child(3){
      margin-top: -.4375em;
      transform: rotate(-3.01deg);
    }
    &:nth-child(4){
      margin-top: -.1875em;
      margin-left: auto;
      transform: rotate(-1deg);
    }
    &:nth-child(5){
      margin-top: .4375em;
      transform: rotate(-0.01deg);
    }
    &:nth-child(6){
      margin-top: .0625em;
      margin-left: 9.375em;
    }
  }
}

.work-here{
  position: relative;
  margin-top: 10em;
  color: #fff;
  &__slider{
    margin-top: .8125em;
  }
  .swiper-scrollbar{
    width: 100%;
    bottom: -50px;
    left: 0;

    height: 1.25em;
    border-radius: .125em;
    background: rgba(255, 255, 255, 0.6);
    &-drag{
      border-radius: inherit;
      background: #fff;
    }
  }
}

.video{
  display: block;
  position: relative;
  &__play{
    @include centerAbsolute;
    width: 6.875em;
    img{
      width: 100%;
      display: block;
    }
  }
  &__img{
    display: block;
    @include imgCover(50%);
    img{
      border-radius: 1.25em;
    }
  }
}

.main2{
  margin-top: -3.5em;
  padding-top: 13.5em;
  position: relative;
  z-index: 2;
}

.test{
  width: 151px;
  height: auto;
}

@media screen and (max-width: 992px){
  .main{
    padding: 7.5em 0;
    &__inner{
      max-width: 41.5625em;
      margin: 0 auto;
    }
  }

  .who,
  .team,
  .cool{
    .display1{
      margin-top: .2em;
    }
    &__text{
      margin-top: 1.25em;
      padding-left: 6.375em;
    }
  }

  .who{
    padding: 11em 0 10em;
    margin-top: -3.5em;
    &__inner{
      padding-bottom: 20.375em;
    }
    &__lips{
      left: 17.25em;
      width: 13.9375em;
      top: 44.125em;
    }
  }

  .who-img1{
    width: 7.75em;
    border-radius: 1.875em;
    left: 1.5em;
    top: 29.25em;
    z-index: 3;
  }

  .who-img2{
    width: 13.4375em;
    border-radius: 2.75em;
    left: 6.5625em;
    top: 34.6875em;
  }

  .who-img3{
    width: 10em;
    border-radius: 2.5em;
    left: 35.9375em;
    top: 9.1875em;
  }

  .who-img4{
    width: 18.625em;
    border-radius: 2.5em;
    left: 26.875em;
    top: 29.375em;
  }

  .round1{
    top: 13.75em;
    right: 7em;
    width: 4.25em;
    height: 4.25em;
    filter: blur(60.4659px);
  }

  .round2{
    top: 28.4375em;
    left: 1.6875em;
    width: 7.4375em;
    height: 7.4375em;
    filter: blur(105.815px);
  }

  .round3{
    top: 35.0625em;
    left: 12.3125em;
    width: 7.25em;
    height: 7.25em;
    filter: blur(103.148px);
  }

  .round4{
    top: 29.0625em;
    left: 27.5625em;
    width: 7.4375em;
    height: 7.4375em;
    filter: blur(61.8721px);
  }

  .nums{
    margin-top: 10.3125em;
  }

  .num{
    &:not(:last-child){
      margin-right: 12.6875em;
    }
    &__img{
      height: 14.1875em;
      margin-bottom: 1.875em;
    }
    &__ear{
      width: 9em;
      top: -4.375em;
      left: 12em;
    }
    &__girl{
      width: 16.25em;
      top: 4.8125em;
      margin-left: -4.75em;
    }
  }

  .team{
    padding: 7.5em 0;
    overflow: hidden;
    &__text2{
      margin-top: .875em;
    }
    &__slider{
      margin-top: .75em;
    }
    &__ear{
      @media screen and (min-width: 577px){
        top: unset;
        bottom: -5em;
        width: 8.625em;
        left: 2.25em;
        transform: rotate(-180deg);
      }
    }
    &__mask{
      top: -.375em;
      right: 4.9375em;
    }
    .swiper{
      &-slide{
        width: 13em;
        padding-right: .5em;
        transform: rotate(5.97deg);
        &:nth-child(1){
          transform: rotate(5.97deg);
        }
        &:nth-child(2){
          transform: rotate(-5deg);
        }
        &:nth-child(3){
          transform: rotate(2.8deg);
        }
        &:nth-child(4){
          transform: rotate(-4deg);
        }
        &:nth-child(5){
          transform: rotate(3.33deg);
        }
        &:nth-child(6){
          transform: rotate(-4deg);
        }
      }
    }
  }

  .make{
    padding: 10em 0;
    overflow: hidden;
    &__title{
      margin-top: .22em;
    }
    &__items{
      margin: 0;
    }
    &__title2{
      margin-top: -.44em;
    }
    &__lips{
      top: 1.25em;
      right: 4.875em;
      width: 10.875em;
      transform: rotate(-14.96deg);
    }
  }

  .round5{
    left: -3.3125em;
    bottom: 12.9375em;
    width: 16.6875em;
    height: 16.6875em;
  }

  .make-card{
    min-height: unset;
    grid-template-columns: 100%;
    gap: .625em;
    @media screen and (min-width: 577px){
      padding: 2em;
      gap: 0;
      grid-template-columns: 10em auto;
      .text40{
        font-size: 1.5em;
        line-height: 150%;
      }
    }
    &:not(:first-child){
      margin-top: -.6875em;
    }
  }

  .cool{
    padding: 7.5em 0;
    background-image: url(../img/cool2.webp);
    &__butterfly{
      display: none;
    }
    &__text{
      padding-left: 6.25em;
      max-width: 42.25em;
    }
    &__tags{
      margin-top: 2.625em;
    }
    &__tag{
      padding: 1.25em 1.875em;
      min-height: 5.5em;
      border-radius: 1.25em;
      margin: 0 .125em;
      span{
        letter-spacing: unset;
        font-size: 1.5em;
        line-height: 114%;
      }
      &:nth-child(1){
        margin: 0 auto;
      }
    }
  }

  .work-here{
    margin-top: 7.5em;
    &__slider{
      margin-top: 1.25em;
      max-width: calc(100% - 6.25em);
    }
    .swiper-scrollbar{
      display: none;
    }
  }

  .video{
    &__play{
      width: 4.4375em;
    }
    &__img{
      img{
        border-radius: .625em;
      }
    }
  }

  .main2{
    padding-top: 11.25em;
  }
}


@media screen and (min-width: 577px){

}

@media screen and (max-width: 576px){
  .main{
    padding: 6em 0 3.75em;
    border-radius: 0 0 1.25em 1.25em;
    .container{
      padding: 0 1.4375em;
    }
    &__logo{
      margin-bottom: .625em;
    }
    &__btn{
      margin-top: 1.875em;
      width: 9.25em;
      padding: 0;
    }
  }

  .who{
    padding: 5em 0;
    margin-top: -2.5em;
    &__inner{
      padding-bottom: 6.5em;
    }
    &__lips{
      left: 8.75em;
      width: 4.625em;
      top: 13.75em;
    }
  }

  .who,
  .team,
  .cool {
    &__subtitle {
      font-size: .875em;
      line-height: 121%;
    }
    .display1{
      margin-top: .22em;
    }
    &__text{
      margin-top: .625em;
      padding-left: 5.3125em;
    }
  }

  .who-img1{
    width: 3.0625em;
    border-radius: .875em;
    left: .3125em;
    top: 6.6875em;
  }

  .who-img2{
    width: 5.125em;
    border-radius: 1em;
    left: 1.25em;
    top: 11.6875em;
  }

  .who-img3{
    width: 4em;
    border-radius: 1.125em;
    left: 14.5em;
    top: 4em;
  }

  .who-img4{
    width: 7.8125em;
    border-radius: 1em;
    left: 12.5em;
    top: 10.625em;
  }

  .round1{
    top: 0;
    right: 3.625em;
    width: 3.5em;
    height: 3.5em;
    filter: blur(3.0625em);
  }

  .round2{
    top: 8em;
    left: 1.25em;
    width: 2.5em;
    height: 2.5em;
    filter: blur(2.1875em);
  }

  .round3{
    top: 11.25em;
    left: 3.25em;
    width: 2.5em;
    height: 2.5em;
    filter: blur(2.1875em);
  }

  .round4{
    top: 13.125em;
    left: 10.625em;
    width: 5.1875em;
    height: 5.1875em;
    filter: blur(2.6875em);
  }

  .nums{
    margin-top: 1.875em;
  }

  .num{
    &:not(:last-child){
      margin-right: 2.625em;
    }
    &__img{
      height: 8.75em;
      margin-bottom: .625em;
    }
    &__ear{
      width: 4.25em;
      top: -1.0625em;
      left: 10em;
    }
    &__girl{
      width: 7.25em;
      top: 4em;
      margin-left: -3.25em  ;
    }
  }

  .team{
    padding: 3.75em 0;
    overflow: hidden;
    &__text2{
      margin: 0;
    }
    &__slider{
      margin-top: .75em;
    }
    &__ear{
      top: 8.625em;
      left: .625em;
      width: 3.875em;
    }
    &__mask{
      top: -.375em;
      right: 2.25em;
      width: 3.0625em;
    }
  }

  .make{
    padding: 5em 0;
    overflow: hidden;
    &__subtitle{
      font-size: .875em;
      line-height: 121%;
    }
    &__title{
      margin-top: .22em;
    }
    &__items{
      margin: 0;
    }
    &__title2{
      margin-top: -.44em;
    }
    &__lips,
    .round{
      display: none;
    }
  }

  .cool{
    padding: 3.75em 0;
    border-radius: 1.25em;
    margin-top: -1.25em;
    &__butterfly{
      display: none;
    }
    &__text{
      padding-left: 3.125em;
    }
    &__tags{
      margin-top: 2.625em;
      @media screen and (min-width: 577px){
        font-size: 2em;
      }
    }
    &__tag{
      padding: .5em 1.125em;
      min-height: 1.25em;
      border-radius: .625em;
      margin: 0;
      span{
        letter-spacing: unset;
        font-size: .875em;
        line-height: 114%;
      }
      &:nth-child(1){
        transform: rotate(-1deg);
      }
      &:nth-child(2){
        margin-top: .1875em;
        margin-left: 3.625em;
        transform: rotate(1deg);
      }
      &:nth-child(3){
        margin-top: .3125em;
        transform: rotate(-3.01deg);
      }
      &:nth-child(4){
        margin-top: .125em;
        margin-left: -.25em;
        transform: rotate(-1deg);
      }
      &:nth-child(5){
        margin-top: .125em;
        width: calc(100% + 1em);
        transform: rotate(-0.01deg);
      }
      &:nth-child(6){
        margin-top: 0;
        margin-left: 1.25em;
      }
    }
  }

  .work-here{
    margin-top: 3.75em;
    &__slider{
      margin-top: .6875em;
      max-width: 100%;
    }
  }

  .video{
    &__play{
      width: 2.25em;
    }
    &__img{
      img{
        border-radius: .5em;
      }
    }
  }

  .main2{
    margin-top: -2.5em;
    padding-top: 7.5em;
  }
}
