@import "../mixins";
.modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 80;
    opacity: 0;
    pointer-events: none;
    &__bg {
        background: #666570;
        opacity: 0.7;
        position: fixed;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        display: none;
        z-index: 2;
    }
    &--active {
        opacity: 1;
        pointer-events: unset;
        .modal__bg {
            display: block;
        }
    }
    &__dialog {
        border-radius: 1.25em;
        width: 53.625em;
        max-width: calc(100% - 2.5em);
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 100vh;
        position: relative;
        z-index: 3;
        background: #F5F4FB;
        padding: 3.75em 2.5em;
    }
    &__inner{
        max-width: 42.1875em;
        margin: 0 auto;
    }
    &__close{
        position: absolute;
        top: 1.25em;
        right: 1.25em;
        width: 1.0625em;
        svg{
            width: 100%;
            height: auto;
        }
    }
    &__info{
        text-align: center;
        @include grid100gap(1.875em);
    }
    &__desc{
        color: $primary;
        font-size: 1.25em;
        line-height: 110%;
    }
    &__form{
        margin-top: 1.875em;
        &-grid{
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: .625em;
        }
        ._full{
            grid-column: 1/-1;
        }
    }
    &__nav{
        display: flex;
        justify-content: flex-end;
        margin-top: .625em;
    }
    &__btn{
        width: 16.125em;
    }
    .file-input:not(._active){
        display: none;
    }
}

@media screen and (max-width: 992px) {
    .modal {
        &__dialog {
            padding: 3.125em 1.25em 1.875em;
        }
        &__close{
            top: 1.25em;
            right: 1.25em;
        }
        &__info{
            gap: .625em;
        }
        &__title{
            letter-spacing: -0.04em;
        }
        &__form{
            &-grid{
                grid-template-columns: 100%;
            }
        }
        &__btn{
            width: 9.25em;
        }
    }
}
