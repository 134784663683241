@import "../vars";
.m-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all ease-out .225s;
    padding: 0;
    font-family: $font2;
    &:focus {
        box-shadow: none;
    }
}

.m-btn-white{
    padding: 0 1em;
    height: 3.75em;
    border-radius: .625em;
    font-weight: 500;
    color: $black;
    background-color: #fff;
    span{
        font-size: 1.125em;
    }
    &:hover{
        background-color: $primary;
        color: #fff;
    }
}

.m-btn-outline{
    border: 1px solid #FFFFFF;
    border-radius: .625em;
    color: #fff;
    &:hover{
        background-color: #fff;
        color: $black;
    }
}

.m-btn-linear{
    background: radial-gradient(45.02% 45.02% at -14.74% 56.75%, #FFB422 4.07%, rgba(255, 180, 34, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(67.58% 67.58% at -13.86% 53.23%, #FFB422 1.49%, rgba(255, 180, 34, 0) 51.61%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(42.19% 42.19% at -3.33% 41.61%, #FFB422 0%, rgba(255, 180, 34, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(62.28% 49.4% at 28.14% 110.65%, #F457EC 9.89%, rgba(244, 87, 236, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(38.93% 30.88% at 0.94% 24.13%, #FFB422 0%, rgba(255, 180, 34, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(58.88% 46.71% at -6.51% 86.63%, #FF3838 36.46%, rgba(255, 56, 56, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(50.1% 50.1% at 11.69% 14.46%, #F457EC 0%, rgba(244, 87, 236, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    linear-gradient(0deg, #1A9EFE, #1A9EFE);
    border-radius: .625em;
    position: relative;
    &:before{
        content: '';
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        background-color: #F5F4FB;
        border-radius: inherit;
        @include centerAbsolute;
    }
    span{
        position: relative;
        z-index: 2;
    }
}


@media screen and (max-width: 576px){
    .m-btn-white{
        border-radius: .5em;
        height: 1.9375em;
        span{
            font-size: .875em;
        }
    }
}


@media screen and (min-width: 577px){
    //.m-btn-white{
    //    border-radius: .625em;
    //    height: 3.75em;
    //    span{
    //        font-size: 1.125em;
    //    }
    //}
}
