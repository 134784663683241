.alert {
  z-index: 81;
  position: fixed;
  top: 24px;
  right: 24px;
  padding: 16px;
  background-color: #1a9efe;
  color: #fff;
  border-radius: 0.625em;
  transition: 200ms ease-in-out;
  
  &:not(.show) {
    transform: translateX(25%);
    opacity: 0;
    visibility: hidden;
  }

  &.success {

    .alert__error {
      display: none;
    }
  }

  &.error {

    .alert__success {
      display: none;
    }
  }
}