@import 'vars';
html {
    width: 100%;
    font-size: unquote("min(100vw, 1248px)");
}

.wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
}

.content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.page {
    width: calc(100vw - (100vw - 100%));
    overflow-x: hidden;
    font-family: $font1;
    font-weight: 400;
    color: $black;
    font-size: 1.28205%;
    background: #F5F4FB;
    &.fixed-body {
        overflow-y: hidden;
    }
}

.container {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    padding: 0 2.5em;
    &._small{
        max-width: 1100px;
    }
}

a {
    &:hover {
        color: $primary;
    }
}


@media screen and (max-width: 992px){
    .page{
        font-size: 1.91755%;
    }

    .container{
        padding: 0 2em;
    }
}


@media screen and (max-width: 576px){
    .page{
        font-size: 4.2644%;
    }

    .container{
        padding: 0 1.25em;
    }
}
