.footer{
  padding: 13.75em 0 1.875em;
  background-color: $black;
  margin-top: -2.5em;
  color: #FFFFFF;
  font-family: $font2;
  &__inner{
    display: grid;
    grid-template-columns: repeat(4,calc(25% - 3em));
    gap: 4em;
  }
  &__logo{
    display: block;
    max-width: 9.875em;
    img{
      width: 100%;
      display: block;
    }
  }
  &__links{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  &__link{
    &:not(:last-child){
      margin-bottom: 1.875em;
    }
    span{
      font-size: 1.125em;
      line-height: 139%;
    }
  }
  &__btn{
    height: 3.75em;
    width: 100%;
    font-weight: 600;
    &:before{
      background-color: $black;
    }
    span{
      font-size: 1.125em;
    }
  }
  &__hint{
    font-size: 1.125em;
    line-height: 136%;
    a{
      font-weight: 700;
      color: $primary;
    }
  }
  &__hint2{
    margin-top: 3.33em;
  }
  &__socials{
    display: flex;
    margin-top: 1.25em;
  }
  &__social{
    @include center;
    border: 1px solid #FFFFFF;
    width: 3.75em;
    height: 3.75em;
    border-radius: .625em;
    &:hover{
      border-color: $primary;
    }
    img{
      width: 1.8125em;
    }
  }
  &__c{
    margin-top: 1.75em;
    span{
      font-size: .9375em;
      line-height: 125%;
    }
  }
}


@media screen and (max-width: 992px){
  .footer{
    padding: 6.25em 0 1.875em;
    margin-top: -3.75em;
    &__inner{
      grid-template-columns: 1fr 1fr;
      gap: 2.5em 0;
    }
    &__col{

      &:nth-child(1){
        grid-column: 1/-1;
      }
      @media screen and (min-width: 577px){
        max-width: 17.125em;
        &:nth-child(4){
          grid-column: 2/-1;
          margin-top: -5.75em;
        }
      }
    }
    &__logo{
      max-width: 10em;
    }
    &__link{
      &:not(:last-child){
        margin-bottom: 1.875em;
      }
    }
    &__btn{
      font-weight: 500;
      height: 3.75em;
      border-radius: .5em;
      &:before{
        width: calc(100% - 2px);
        height: calc(100% - 2px);
      }
      span{
        font-size: .875em;
      }
    }
    &__c{
      margin-top: 6.875em;
    }
  }
}


@media screen and (max-width: 576px){
  .footer{
    padding: 5em 0 1.25em;
    margin-top: -1.25em;
    &__inner{
      gap: 2.5em 0;
    }
    &__col{
      &:nth-child(1),
      &:nth-child(3),
      &:nth-child(4){
        grid-column: 1/-1;
      }
      &:nth-child(3){
        display: flex;
      }
    }
    &__logo{
      max-width: 5.625em;
    }
    &__link{
      &:not(:last-child){
        margin-bottom: .625em;
      }
    }
    &__btn{
      height: 2.5em;
      width: auto;
      padding: 0 .625em;
    }
    &__hint2{
      margin-top: 1.67em;
    }
    &__c{
      margin-top: 3.75em;
    }
  }
}
