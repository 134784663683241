.main-vacancy{
  padding-bottom: 6.25em;
}

.faq{
  padding: 10em 0 1.25em;
  background-color: $black;
  color: #fff;
  border-radius: 2.5em 2.5em 0 0;
  overflow: hidden;
  position: relative;
  z-index: 2;
  &__inner{
    position: relative;
  }
  &__title{
    font-family: $font2;
    font-weight: 600;
    font-size: 4.375em;
    letter-spacing: -0.03em;
  }
  &__items{
    margin-top: 3.75em;
  }
}

.round7{
  background-color: $primary;
  top: 1.9375em;
  left: 1.125em;
}

.round8{
  width: 18.8125em;
  height: 18.8125em;
  opacity: .67;
  top: 6em;
  left: 8em;
}

.qa-card{
  padding: 1.25em 0;
  border-top: 3px solid #4F4F4F;
  font-family: $font2;
  &:last-child{
    border-bottom: 3px solid #4F4F4F;
  }
  &__show{
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all .3s;
    &:hover{
      color: $primary;
    }
  }
  &__title{
    font-size: 2.1875em;
    line-height: 114%;
    letter-spacing: -0.03em;
  }
  &__btn{
    width: 1.375em;
    flex-shrink: 0;
    margin-left: 1em;
    svg{
      width: 100%;
      height: auto;
    }
  }
  &__wrap{
    padding: 1.25em 1em 0 0;
    width: 53.125em;
    max-width: 100%;
    margin-left: auto;
  }
  &__text{
    font-size: 1.375em;
    line-height: 132%;
    letter-spacing: -0.03em;
  }
  &.slideToggle-block--active{
    .qa-card__btn svg{
      transform: rotate(-45deg);
    }
  }
}

.not-found{
  margin-top: 8.75em;
  position: relative;
  &__lips{
    position: absolute;
    right: 8.75em;
    top: 9.875em;
    width: 13.9375em;
    display: block;
    transform: rotate(-14.96deg);
  }
  &__text{
    margin: 4.125em 0 0 auto;
    width: 52.875em;
    max-width: 100%;
    a{
      color: $primary;
      &:hover{
        text-decoration: underline;
      }
    }
  }
}

.vacancies{
  padding: 10em 0;
  &__items{
    margin-top: 2.5em;
    @include grid100gap(.625em);
  }

  &__title {
    font-family: $font1;
    font-size: 2.5em;
    font-weight: 500;
    line-height: 1.20;
    letter-spacing: -0.03em;
    color: $black2;
  }
}

.vacancy{
  padding: 30px;
  background: #FAFCFF;
  border-radius: 1.25em;
  transition: all .3s;
  &__top{
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
  }
  &__title{
    font-weight: 500;
    font-size: 2.8125em;
    letter-spacing: -0.04em;
  }
  &__btn{
    width: 1.375em;
    flex-shrink: 0;
    margin-left: 1em;
    svg{
      width: 100%;
      height: auto;
    }
  }
  &__tags{
    display: flex;
    flex-wrap: wrap;
    margin-top: 3.5em;
  }
  &__tag{
    padding: .3125em .625em;
    margin: .25em .1875em 0 0;
    min-height: 2em;
    border-radius: .4375em;
    font-weight: 500;
    display: flex;
    align-items: center;
    color: #fff;
    span{
      font-size: 1.125em;
      line-height: 133%;
    }
  }
  &__wrap{
    padding: 3.75em 0 0;
    max-width: 37.75em;
  }
  &:hover,
  &.slideToggle-block--active{
    background: $black;
    color: #fff;
    position: relative;
    overflow: hidden;
    &:before,
    &:after{
      content: '';
      position: absolute;
      width: 11em;
      height: 11em;
      border-radius: 50%;
      background-color: $primary;
      filter: blur(9.75em);
      top: -2.625em;
      left: -2.75em;
      pointer-events: none;
    }
    &:after{
      background: #1A9EFE;
      top: -1em;
      right: -1.875em;
      left: unset;
    }
    .vacancy{
      &__btn{
        path{
          fill: #F5F4FB;
        }
      }
    }
    .vacancy-text{
      ul li{
        &:before{
          background-color: #fff;
        }
      }
      &__group,
      &__tag{
        &:before{
          background: $black;
        }
      }
    }
  }
  &.slideToggle-block--active{
    .vacancy__btn svg{
      transform: rotate(-45deg);
    }
  }
}

.vacancy-text{
  &__tags{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: -.3125em;
  }
  &__group{
    padding: 1.25em;
    &:before{
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      background: #fafcff;
    }
    & *{
      position: relative;
      z-index: 2;
    }
  }
  &__tag{
    padding: .3125em .625em;
    font-weight: 400;
    min-height: 2em;
    border-radius: .4375em;
    margin: .3125em .3125em 0 0;
    span{
      font-size: 1.125em;
      line-height: 133%;
    }
    &:before{
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      background: #fafcff;
    }
  }
  &__nav{
    display: flex;
    margin-top: 30px;
  }
  &__btn{
    max-width: 100%;
    width: 16.8125em;
    font-weight: 600;
  }
  p,
  h3,
  li{
    font-size: 1.125em;
    line-height: 122%;
    &:not(:last-child){
      margin-bottom: 1.11em;
    }
  }
  p,li{
    font-weight: 400;
  }
  h3{
    font-weight: 500;
  }
  hr{
    border: none;
    margin-top: 1.875em;
  }
  ul{
    @include grid100gap(.5em);
    &:not(:last-child){
      margin-bottom: 1.11em;
    }
    li{
      display: flex;
      &:before{
        content: '';
        flex-shrink: 0;
        width: .27em;
        height: .27em;
        border-radius: 50%;
        background-color: $black;
        margin-right: .66em;
        transform: translateY(.5em);
      }
    }
  }
  li{
    &:not(:last-child){
      margin-bottom:0;
    }
  }
}


@media screen and (max-width: 992px){
  .main-vacancy{
    padding-bottom: 7.5em;
    .main__inner{
      max-width: 100%;
    }
  }


  .faq{
    padding: 7.5em 0 6em;
    &__title{
      font-size: 3.375em;
      line-height: 133%;
    }
    &__items{
      margin-top: 3.75em;
    }
  }

  .qa-card{
    padding: 1.25em 0;
    &__title{
      font-size: 1.75em;
      line-height: 114%;
    }
    &__wrap{
      padding: .625em 2.5em 0 0;
    }
    &__text{
      font-size: 1.25em;
      line-height: 110%;
    }
  }

  .round7{
    top: .875em;
    left: .3125em;
    width: 7.125em;
    height: 7.125em;
  }

  .round8{
    top: 3.375em;
    left: 6.25em;
    width: 12.25em;
    height: 12.25em;
  }

  .not-found{
    margin-top: 6.25em;
    &__text{
      margin-top: 1.875em;
      max-width: 41.8125em;
      width: 100%;
      margin-left: 0;
    }
    &__lips{
      right: 3.375em;
      width: 11.875em;
    }
  }

  .vacancies{
    padding: 7.5em 0;
    &__title {
      font-size: 2em;
      line-height: 1.6;
    }
    &__items{
      margin-top: 1.25em;
    }
  }

  .vacancy{
    &__tags{
      margin-top: 2.5em;
    }
    &__tag{
      span{
        font-size: 1em;
      }
    }
    &__wrap{
      padding: 3.75em 0 0;
    }
    &._dark{
      &:before,
      &:after{
        width: 6.25em;
        height: 6.25em;
      }
      &:before{
        top: 0;
        left: -1.3125em;
      }
      &:after{
        top: 6.25em;
        right: -4.0625em;
      }
    }
  }


}


@media screen and (max-width: 576px){
  .main-vacancy{
    padding-bottom: 5em;
  }

  .faq{
    padding: 3.75em 0 1em;
    border-radius: 1.25em 1.25em 0 0;
    &__title{
      font-size: 2.5em;
      line-height: 120%;
    }
    &__items{
      margin-top: 2em;
    }
  }

  .qa-card{
    padding: .5em 0;
    border-width: 2px !important;
    &__title{
      font-size: .875em;
      line-height: 121%;
    }
    &__wrap{
      padding: .625em 2.5em 0 0;
    }
    &__text{
      font-size: .875em;
      line-height: 114%;
      color: #C0C0C0;
    }
  }

  .not-found{
    margin-top: 3.75em;
    &__text{
      margin-top: 1.25em;
    }
    &__lips{
      display: block;
      margin-top: 1.5em;
      position: static;
      width: 7.6875em;
    }
  }

  .vacancies{
    padding: 3.75em 0;
    &__title{
      font-size: 0.9em;
      line-height: 1.21;
    }
  }

  .vacancy{
    padding: 20px;
    &__title{
      font-size: 1.75em;
      font-weight: 600;
    }
    &__tags{
      margin-top: 1.625em;
    }
    &__tag{
      font-weight: 400;
      span{
        font-size: .875em;
      }
    }
    &__wrap{
      padding: 1.875em 0 0;
    }
    &._dark{
      &:before,
      &:after{
        width: 6.25em;
        height: 6.25em;
      }
      &:before{
        top: 0;
        left: -1.3125em;
      }
      &:after{
        top: 6.25em;
        right: -4.0625em;
      }
    }
  }

  .vacancy-text{
    &__tags{
      span{
        font-size: .9375em;
      }
    }
    &__nav{
      margin-top: 20px;
    }
    &__btn{
      width: 9.25em;
    }
    h3,p,li{
      font-size: .9375em;
      line-height: 133%;
    }
    h3,p{
      &:not(:last-child){
        margin-bottom: .67em;
      }
    }
    ul{
      gap: 0;
    }
  }
}
