.header{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  padding: 1.25em 0;
  &__inner{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__logo{
    display: block;
    width: 10em;
    flex-shrink: 0;
    img{
      width: 100%;
      display: block;
    }
  }
}

.menu{
  display: flex;
  align-items: center;
}

.nav{
  display: flex;
  align-items: center;
  &__link{
    font-family: $font2;
    color: #fff;
    &._hidden{
      display: none;
    }
    span{
      font-size: 1.125em;
      line-height: 139%;
    }
    &:not(:last-child){
      margin-right: 1.875em;
    }
    &:hover{
      color: $black;
    }
  }
}

.header-buttons{
  display: flex;
  align-items: center;
  margin-left: 4.0625em;
  &__in{
    width: 1.875em;
    &:hover{
      img{
        transform: translateY(-15%);
      }
    }
    img{
      width: 100%;
      transition: inherit;
    }
  }
  &__call{
    width: 10em;
    flex-shrink: 0;
    margin-left: 1.875em;
    height: 2.625em;
  }
}

.burger{
  display: none;
}

@media screen and (max-width: 992px) {
  .header {
    &__logo {
      position: relative;
      z-index: 11;
      width: 5.625em;
    }

    &._open {
      .burger {
        &:before {
          transform: translate(0, .25em) rotate(-45deg);
        }

        &:after {
          width: 100%;
          transform: translate(0, -.25em) rotate(45deg);
        }
      }

      .menu {
        opacity: 1;
        pointer-events: unset;
      }
    }
  }

  .menu{
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    opacity: 0;
    pointer-events: none;
    transition: all .3s;
    background: $black;
    padding: 5em 1.25em 2em;
    flex-direction: column;
    align-items: flex-start;
  }

  .nav{
    flex-direction: column;
    align-items: flex-start;
    &__link{
      margin: 0 0 2em;
    }
  }

  .header-buttons{
    margin: 1em 0 0;
  }

  .burger{
    position: relative;
    z-index: 11;
    height: .6875em;
    width: 1.875em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &:before,
    &:after{
      content: '';
      display: block;
      height: .1875em;
      width: 100%;
      background-color: #fff;
      transition: all .3s;
      border-radius: .1em;
    }
    &:after{
      width: 50%;
      margin-left: auto;
    }
  }

}
