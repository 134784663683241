@import "../vars.scss";
.breadcrumbs {
    margin-top: 60px;
    &__item {
        font-weight: 600;
        font-size: 16px;
        line-height: 110%;
        position: relative;
        font-weight: 400;
        display: inline;
        &:last-child {
            font-weight: 600;
        }
        &:not(:first-child) {
            margin-left: 24px;
            &:before {
                content: '';
                position: absolute;
                z-index: 1;
                width: 3.51px;
                height: 3.51px;
                background-color: $primary;
                border-radius: 1px;
                transform: rotate(-45deg);
                top: 7px;
                right: 100%;
                margin-right: 10px;
            }
        }
    }
}