@import "../mixins";
.fg {
  position: relative;
  margin: 0;
  input,
  textarea{
    border: 1px solid #A2A0B1;
    padding: 0 .71em;
    height: 2.85em;
    width: 100%;
    font-size: .875em;
    background-color: transparent;
    border-radius: .71em;
    color: $black;
    transition: 100ms ease-in-out;

    &::placeholder {
      color: #A2A0B1;
    }

    &.error {
      border-color: rgb(232, 53, 53);
    }
  }
  textarea{
    resize: none;
    padding-top: .71em;
    padding-bottom: .71em;
    height: 10em;
  }
}

.m-check,
.file-input{
  position: relative;
  input{
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 2;
    cursor: pointer;
  }
  span{
    font-size: .875em;
    line-height: 114%;
  }
}

.m-check {
  input {
    &:checked+label {
      &::before {
        background-image: url(../img/check.svg);
      }
    }
  }
  label {
    min-height: 1em;
    font-size: 1em;
    display: flex;
    align-items: center;
    font-family: $font2;
    &:before {
      content: '';
      z-index: 1;
      border-radius: .3125em;
      border: 1px solid #A2A0B1;
      width: 1em;
      height: 1em;
      flex-shrink: 0;
      transition: all .100s;
      margin-right: .625em;
      @include bgImgSize(.375em);
    }
  }
}

.file-input{
  &__wrap{
    padding: .5em;
    border: 1px dashed #A2A0B1;
    border-radius: .625em;
    @include center;
    svg{
      width: 1.5em;
      height: auto;
      margin-right: .125em;
    }
  }
}


@media screen and (max-width: 992px){
  .file-input{
    &__wrap{
      padding: 1.375em 2.375em;
      border-radius: .5em;
      flex-direction: column;
      text-align: center;
      svg{
        margin: 0 0 .125em;
      }
    }
  }
}
