.display1{
  font-weight: 500;
  font-size: 8.75em;
  letter-spacing: -0.04em;
}

.display2{
  font-size: 4em;
  line-height: 112%;
  letter-spacing: -0.02em;
}

.text45,
.text40{
  font-weight: 500;
  font-size: 2.5em;
  line-height: 120%;
  letter-spacing: -0.02em;
}

.text45{
  font-size: 2.8125em;
}

.text22{
  font-size: 1.375em;
  line-height: 132%;
  letter-spacing: -0.03em;
}

.medium-text{
  font-weight: 500;
}

.accent-text{
  color: $primary;
}


@media screen and (max-width: 992px){
  .display1{
    font-size: 6.25em;
    line-height: 110%;
  }

  .display2{
    font-size: 3.375em;
  }

  .text40{
    font-size: 1.875em;
  }

  .text22{
    font-size: 1.25em;
  }
}


@media screen and (max-width: 576px){
  .display1{
    font-size: 2.8125em;
  }

  .display2{
    font-size: 1.25em;
    line-height: 110%;
  }

  .text40{
    font-size: 1.125em;
  }
}
