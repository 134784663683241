@import 'vars';
@import 'components/_text.scss';
@import 'components/_buttons.scss';
@import "components/_inputs.scss";
.slideToggle-block {
  &__show{
    cursor: pointer;
  }
  &__hidden {
    height: 0;
    overflow: hidden;
    transition: all .425s;
  }
}
